@media (max-width: 450px) {
    #mobius-h{
        font-size:10vw;
        padding:0;
    }
    #mobius-p{
        font-size:4vw;
        padding-bottom:20vw;
    }
}

@media(min-width:451px) and (max-width: 660px) {
    #mobius-h{
        font-size:7vw;
        padding:0;
    }
    #mobius-p{
        font-size:3vw;
        padding-top:20px;
        padding-bottom:10vw;
    }
}
