.contact-form-container {
    width: 60%;
    margin: 5vw auto;
}

.contact-title-box {
    padding: 2vw 0;

}

.contact-form-container h1 {
    font-size: 2vw;
    font-family: 'JSans-500';
    margin-bottom: 0.5vw;
}

.contact-form-container p {
    font-size: 1vw;
    font-family: 'JSans-300';
}

.contact-form-input-container {
    margin: 2vw 0;
}


.contact-form-label {
    font-size: 1.2vw;
    font-family: 'JSans-600';
}

.contact-form-input {
    display: block;
    border: solid #d4d4d4;
    border-width: 1.5px;
    border-radius: 5px;
    width: 30%;
    height: 2.3vw;
    padding: 0 0.5vw;
    margin-top: 1vw;
    font-family: 'JSans-600';
    font-size: 1vw;
}

#contact-inquiry {
    border-width: 1px;
    height: 10vw;
    width: 100%;
}

.contact-required {
    display: inline-block;
    color: #e21818;
    font-family: 'JSans-400';
}

.contact-char-limit {
    float: right;
}

.contact-submit-btn {
    float: right;
    width: 10vw;
    height: 3vw;
    background-color: #e21818;
    border-radius: 5px;
    font-size: 1vw;
    font-family: 'JSans-700';
    color: white;
}

.contact-text {
    padding-top: 5vw;
    font-size: 1vw;
    font-family: 'JSans-300';
}


/*모바일 */
@media (max-width: 549px) {

    .contact-form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .contact-body {
        padding: 20vw 5vw;
        width: 100%;
    }

    .contact-title-box {
        padding: 6vw 0;

    }

    .contact-form-container h1 {
        font-size: 7vw;
        margin-bottom: 1vw;
    }

    .contact-form-container p {
        font-size: 3vw;
        font-family: 'JSans-300';
    }


    .contact-form-input-container {
        margin: 2vw 0;
    }


    .contact-form-label {
        font-size: 4vw;
        font-family: 'JSans-600';
    }

    .contact-form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 10vw;
        margin-top: 2vw;
        font-family: 'JSans-500';
        font-size: 4vw;
        padding: 0 2vw;
    }

    #contact-inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .contact-submit-btn {
        width: 50vw;
        height: 10vw;
        border-radius: 1vw;
        font-size: 4vw;
    }

    .contact-text {
        font-size: 2.5vw;
        font-family: 'JSans-300';
        padding-top: 15vw;

    }


}


/*모바일*/
@media (min-width: 550px) and (max-width: 991px) {
    .contact-form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .contact-body {
        padding: 20vw 5vw;
        width: 100%;
    }

    .contact-title-box {
        padding: 30px0;

    }

    .contact-form-container h1 {
        font-size: 25px;
        margin-bottom: 1vw;
    }

    .contact-form-container p {
        font-size: 18px;
        font-family: 'JSans-300';
    }


    .contact-form-input-container {
        padding: 10px 0;
    }


    .contact-form-label {
        font-size: 20px;
        font-family: 'JSans-600';
    }

    .contact-form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 60px;
        margin-top: 5px;
        font-family: 'JSans-500';
        font-size: 20px;
        padding: 0 2vw;
    }

    #contact-inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .contact-submit-btn {
        width: 50vw;
        height: 10vw;
        border-radius: 1vw;
        font-size: 25px;
    }

    .contact-text {
        font-size: 15px;
        font-family: 'JSans-300';
        padding-top: 15vw;

    }

}


/*태블릿*/
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .contact-body {
        padding: 100px;
        width: 100%;
    }

    .contact-title-box {
        padding: 30px 0;

    }

    .contact-form-container h1 {
        font-size: 25px;
        margin-bottom: 1vw;
    }

    .contact-form-container p {
        font-size: 18px;
        font-family: 'JSans-300';
    }


    .contact-form-input-container {
        padding: 10px 0;
    }


    .contact-form-label {
        font-size: 20px;
        font-family: 'JSans-600';
    }

    .contact-form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 60px;
        margin-top: 5px;
        font-family: 'JSans-500';
        font-size: 20px;
        padding: 0 2vw;
    }

    #contact-inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .contact-submit-btn {
        width: 300px;
        height: 80px;
        border-radius: 1vw;
        font-size: 25px;
    }

    .contact-text {
        font-size: 15px;
        font-family: 'JSans-300';
        padding-top: 15vw;

    }
}