.hamburger2{
  display:none;
}

.header-component{
    height:500px;
    width:100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
    color: white;
  }

.header-component-text-section{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
  }

  .header-component-nav{
    position:relative;
    width: 100%;
    height: 130px;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }  

  .header-component-logo{
    width:20vw;
    height:auto;
    align-content: end;
    margin-bottom: 7px;
  }
  
  .header-component-logo-img{
    height: auto;
    width: 14vw;
    margin:0 auto;
    }

  
  .header-nav-list {
    align-content: center;
  }
  
  .header-component-nav-list ul{
  display: flex;
  align-items: end;
  height:100%;
  font-size: 28px;
  font-family: 'JSans-600';
}
  
  .header-component-nav-list ul li{
    display:inline-block;
    align-items: end;
    width:170px;
  }
  
  #header-video2 {
    z-index: -1;
    position:absolute;
    top:0;
    width:100%;
    height:100%;
  }

  .mobius-container{
    height: 500px;
    width:100%;
  }

/*모바일 */
@media (max-width: 660px) {
  .header-component {
    height:250px;
    z-index: -1;
    }

    .background-video2{
      height:300px;
    }
  


  .body-size{
    width:90%;
  }


  .header-component-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    height: 60px;
    background-color: transparent;
    /* 초기 배경은 투명 */
    transition: background-color 0.5s ease;
    /* 배경색이 천천히 바뀌도록 설정 */
    z-index: 1000;
    padding: 10px;
  }

  .header-component-nav.fixed {
    background-color: rgb(255, 255, 255);
  }

  .header-component-nav.open{
    background-color: rgb(255, 255, 255);
  }


  .header-component-logo {
    width: 140px;
    height: 100%;
    align-content: center;
    margin: 0;
  }

  .header-component-logo-img {
    margin-left: 20px;
    width: 100px;
  }  

  .hamburger2{
    display: block;
    align-content: center;
    margin-right: 20px;
  }

  #hamburger2{
    width: 35px;
    height: 35px;
  }


  .header-component-nav-list{
    display:none;
  }

}

/*모바일*/
@media (min-width: 661px) and (max-width: 991px) {
  .header-component{
    height:300px;
  }

  .body-size{
    width:90%;
  }

  .header-component-nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    height: 95px;
    background-color: transparent;
    transition: background-color 0.5s ease;
    z-index: 1000;
    padding: 10px;
  }

  .header-component-nav.fixed {
    background-color: rgb(255, 255, 255);

  }

  .header-component-nav.open{
    background-color: rgb(255, 255, 255);
  }



  .header-component-logo {
    width: 25vw;
    height: 100%;
    align-content: center;
    margin: 0;
  }

  .header-component-logo-img {
    margin-left: 35px;
    width: 140px;
  }

  .hamburger2{
    display: block;
    align-content: center;
    margin-right: 35px;
  }

  #hamburger2{
    width: 45px;
    height: 45px;
  }

  .header-component-nav-list{
    display:none;
  }


  #video1 {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
  }


}


/*태블릿*/
@media (min-width: 992px) and (max-width: 1199px) {
    .sortex-container {
      font-family: 'PlusJakartaSans', Arial, sans-serif;
      color: #333;
      overflow-x: hidden
    }  
  
    .header-component-nav {
      width: 100%;
      height: 100px;
      padding: 15px;
    }
  
    .header-component-logo{
      height:auto;
      align-content: end;
      margin-bottom:10px;
    }
  
    .header-component-logo-img {
      margin-left:35px;
    }  
  
    .header-component-nav-list ul {
      font-size: 2.5vw;
      font-family: 'JSans-600';
    }
  
    .header-component-nav-list ul li {
      display: inline-block;
      width: 17vw;
    }
  

}

@media (min-width: 1200px) {
  .header-component{
    height:500px;
    width:100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
    color: white;
  }

}