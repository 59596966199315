body {
  overflow-x: hidden;
}

.navbar {
  display: none;
}

.hamburger {
  display: none;
}

.header-text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  height: 100%;
}

.header-top {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.sortex-logo {
  width: 20vw;
  align-content: end;
  margin-bottom: 7px;
}

.sortex-logo img {
  height: auto;
  width: 14vw;
  margin: 0 auto;
}

.header-section {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}


.header-list-con {
  align-content: center;
}

.header-list-con ul {
  display: flex;
  align-items: end;
  height: 100%;
}

.header-list-con ul li {
  display: inline-block;
  align-items: end;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

#header-video {
  margin: auto 0;
  width: 100%;
  display: flex;
  justify-content: start;
}

.header-text {
  z-index: 1;
  margin: 0 auto;
}

.header-section h1 {
  font-family: 'JSans-700';
  text-align: center;
  font-size: 4vw;
  margin: 0;
}

.header-section p {
  text-align: left;
  font-family: 'JSans-600';
  font-size: 2vw;
  margin: 0;
}


.body-section {
  width: 100vw;
  height: auto;
}

.button-section {
  height: auto;
  width: 100%;
}

.solution-section {
  height: 900px;
  background-color: E5E5E5;
}


.body-size {
  width: 70%;
  margin: 0 auto;
}

.button-section-text {
  margin: 0 auto;
  text-align: center;
}

.button-section-title {
  display: inline-block;
  font-family: 'JSans-700';
  font-size: 3.5vw;
  padding: 150px 0px;
}


.button-section-title-red {
  color: red;
}

.button-section-p {
  font-family: 'JSans-300';
  font-size: 1.7vw;
}

.button-section-p-bold {
  font-family: 'JSans-700';
}

.button-section-imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 100px 0px;
}

.button-section-img {
  width: 30%;
  aspect-ratio: 1;
  object-fit: cover;
  margin: 0px 10px;
  border-radius: 8%;
}

.collect-button-container {
  padding: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 7vw;
}

.collect-button {
  width: 20vw;
  height: 4vw;
  background-color: #e21818;
  border-radius: 0.7vw;
  font-size: 1.3vw;
  font-family: 'JSans-700';
  color: white;
}

.solution-section {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  padding-bottom: 10vw;
}

.solution-img-container {
  width: 70%;
  margin: 0 auto;
}

.solution-img-container h4 {
  height: 10vw;
  align-content: end;
  font-size: 2.3vw;
  font-family: 'JSans-700';
  color: #e21818;
}

.solution-img-container img {
  width: 100%;
  height: auto;
}

.v-line {
  width: 1px;
  height: 10vw;
  background-color: #616161;
  margin: 4vw auto;
}

.solution-section-p {
  margin: 0 auto;
  text-align: center;
  font-family: 'JSans-300';
  font-size: 1.5vw;
}

.solution-section-p-bold {
  font-family: 'JSans-700';
}

.technology-section {
  height: auto;
  background-color: black;
  padding: 10vw 0;
}


.technology-title-h4 {
  font-family: 'JSans-700';
  font-size: 2.3vw;
  color: #e21818;
  padding-left: 9.5%;
}

.technology-video2-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.h-line {
  width: 81%;
  height: 1px;
  background-color: #616161;
  margin: 5vw auto;
}

.technology-video {
  width: 81%;
  margin: 0 auto;
  z-index: 1;
  height: auto;
}

.technology-video-p {
  font-size: 2vw;
  font-family: 'JSans-300';
  text-align: center;
  color: white;
}

.technology-video-p span {
  font-family: 'JSans-700';
}

.box-container {
  margin: 10vw auto 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.box-navigator {
  width: 33%;
  height: 20vw;
  padding: 20px;
  background-color: #281616;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(63, 63, 63, 0.1); 
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* hover 시 애니메이션 */
}

#box1:hover {
  background-color: #442525;
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

#box2:hover {
  background-color: #442525;
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

#box3:hover {
  background-color: #442525;
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.box-navigator h1 {
  font-family: 'Work-700';
  font-size: 1.6vw;
  margin-bottom: 10px;
}

.box-navigator p {
  font-family: 'JSans-200';
  font-size: 1.3vw;
}
/*                           모바일    작은사이즈                      */

@media (max-width: 660px) {

  #header-video {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100v;
    z-index: -1;
  }

  .body-size {
    width: 90%;
  }


  .header-top {
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    height: 60px;
    background-color: transparent;
    transition: background-color 0.5s ease;
    z-index: 1000;
    padding: 10px;
  }

  .header-top.fixed {
    background-color: rgb(255, 255, 255);
  }

  .header-top.open{
    background-color: rgb(255, 255, 255);
  }


  .sortex-logo {
    width: 140px;
    height: 100%;
    align-content: center;
    margin: 0;
  }

  .sortex-logo img {
    margin-left: 20px;
    width: 100px;
  }



  .hamburger {
    display: block;
    align-content: center;
    margin-right: 20px;
  }

  #hamburger {
    width: 35px;
    height: 35px;
  }


  .header-list-con {
    display: none;
  }

  #video1 {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .header-text {
    z-index: 1;
    padding-bottom: 30px;
  }


  .header-section h1 {
    font-size: 9vw;
    font-family: 'JSans-800';
    text-align: center;
    margin: 0;
  }

  .header-section p {
    font-size: 4.5vw;
    text-align: center;
    font-family: 'JSans-600';
    margin: 0;
  }

  .body-section {
    z-index: 1;
    background-color: white;
  }

  .button-section-title {
    padding: 15vw 0 10vw 0;
    font-size: 5vw;
  }

  .button-section-p {
    font-size: 3.2vw;
  }

  .button-section-imgContainer {
    padding: 8vw 0;
  }

  .button-section-img {
    width: 28%;
    aspect-ratio: 1;
    object-fit: cover;
    margin: 0px 2vw;
    border-radius: 8%;
  }
  

  .collect-button {
    width: 40vw;
    height: 10vw;
    border-radius: 5px;
    font-size: 3.5vw;
  }

  .solution-section {
    padding-bottom: 200px;
  }

  .solution-img-container {
    padding-top: 100px;
    width: 100%;
  }

  .solution-img-container h4 {
    height: 50px;
    font-size: 6vw;
    padding-bottom: 20px;
  }

  .v-line {
    height: 100px;
    margin: 70px auto;
  }

  .solution-section-p {
    font-size: 4vw;

  }

  .technology-title-h4 {
    height: 50px;
    font-size: 6vw;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .technology-video-p {
    font-size: 4vw;
    margin-bottom: 200px;
  }

  .technology-video {
    margin: 0;
    width: 100%;
  }

  .h-line {
    width: 100%;
  }
  .box-container{
    width:90%;
    flex-direction: column;
  }

  .box-navigator {
    width:100%;
    height:120px;
    padding:15px;
    margin:5px auto;
  }
  
  
  .box-navigator h1{
    width:100%;
    font-size:16px;
    padding:10px 0;
    margin:0;
  }
  
  .box-navigator p{
    width:100%;
    font-size:12px;
    padding:10px 0;
  }

  .box-navigator br{
    display: none;
  }
  
}

/*                             모바일                                    */

@media (min-width: 661px) and (max-width: 991px) {

  #header-video {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100v;
    z-index: -1;
  }

  .body-size {
    width: 90%;
  }


  .header-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    height: 95px;
    background-color: transparent;
    transition: background-color 0.5s ease;
    z-index: 1000;
    padding: 10px;
  }

  .header-top.fixed {
    background-color: rgb(255, 255, 255);
  }

  .header-top.open{
    background-color: rgb(255, 255, 255);
  }


  .sortex-logo {
    width: 25vw;
    height: 100%;
    align-content: center;
    margin: 0;
  }

  .sortex-logo img {
    margin-left: 35px;
    width: 140px;
  }


  .hamburger {
    display: block;
    align-content: center;
    margin-right: 35px;
  }

  #hamburger {
    width: 45px;
    height: 45px;
  }

  .header-list-con {
    display: none;
  }

  #video1 {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .header-text {
    z-index: 1;
    padding-bottom: 30px;
  }

  .header-section h1 {
    font-size: 9vw;
    font-family: 'JSans-800';
    text-align: center;
    margin: 0;
  }

  .header-section p {
    font-size: 4vw;
    text-align: center;
    font-family: 'JSans-600';
    margin: 0;
  }

  .body-section {
    z-index: 1;
    background-color: white;
  }

  .button-section-title {
    padding: 15vw 0 5vw 0;
    font-size: 5vw;
  }

  .button-section-p {
    font-size: 3.2vw;
  }

  .button-section-imgContainer {
    padding: 4vw 0;
  }

  .collect-button {
    width: 30vw;
    height: 8vw;
    border-radius: 15px;
    font-size: 2.5vw;
  }

  .solution-img-container {
    padding-top: 100px;
    width: 100%;
  }

  .solution-img-container h4 {
    height: 170px;
    font-size: 4.5vw;
    padding-bottom: 50px;
  }

  .v-line {
    height: 100px;
  }

  .solution-section-p {
    font-size: 3vw;
  }

  .technology-title-h4 {
    font-size: 4.5vw;
    padding: 0 0 50px 0;
  }

  .technology-video-p {
    font-size: 3vw;
  }

}


/*                               태블릿                            */

@media (min-width: 992px) and (max-width: 1199px) {
  .sortex-container {
    font-family: 'PlusJakartaSans', Arial, sans-serif;
    color: #333;
    overflow-x: hidden
  }


  #header-video {
    height: 175px;
  }


  .header-top {
    width: 100%;
    height: 100px;
    padding: 15px;
  }

  .sortex-logo {
    height: auto;
    align-content: end;
    margin-bottom: 10px;
  }

  .sortex-logo img {
    margin-left: 35px;
  }

  .header-list-con ul {
    font-size: 2.5vw;
    font-family: 'JSans-600';
  }

  .header-list-con ul li {
    display: inline-block;
    width: 17vw;
  }



  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .header-section h1 {
    font-size: 6vw;
  }

  .header-section p {
    font-size: 3.5vw;
  }

  .section-title {
    color: red;
    font-family: 'JSans-700';
    font-size: 27px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .solution-section-p {
    font-size: 2vw;
  }


}



/*                                     PC                                     */

@media (min-width: 1200px) {
  .header-top {
    height: 130px;
    padding: 20px;
  }


  .header-top {
    width: 100%;
    height: 130px;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .header-list-con ul {
    font-size: 28px;
    font-family: 'JSans-600';

  }

  .header-list-con ul li {
    width: 170px;
  }

  .section-title {
    color: red;
    font-family: 'JSans-700';
    font-size: 35px;
    text-transform: uppercase;
  }
}