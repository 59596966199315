.footer-body {
    background-color: #E5E5E5;
    width: 100vw;
    height: 36vw;
    padding: 8vw 10vw;
    align-content: center;
}

.sortex-logo-grey {
    width: 20vw;
}

.nav-container {
    font-size: 1.8vw;
    font-family: 'JSans-300';
    color: #737373;
}

.nav-container-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2.5vw;
    width: auto;
}

.nav-list {
    display: flex;
    flex-direction: column;
}


.nav-list-item {
    margin-bottom: 2vw;
}



.logo-container {
    display: flex;
    flex-direction: row;
}

#youtube-logo-svg {
    width: 3vw;
    height: 3vw;
    margin-right: 0.5vw;
}


.instagram-svg {
    width: 2vw;
    margin: 0.5vw;
}




/*모바일 */
@media (max-width: 660px) {
    .footer-body {
        background-color: #E5E5E5;
        width: 100vw;
        height: 36vw;
        padding: 2vw 3vw;
        align-content: start;
    }

    .sortex-logo-grey {
        width: 40vw;
        margin-left: -4vw;
    }

    .nav-container {
        font-size: 3vw;
        font-family: 'JSans-300';
        color: #737373;
    }

    .nav-container-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 1.2vw;
        width: auto;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
    }


    .nav-list-item {
        margin-bottom: 2vw;
    }



    .logo-container {
        display: flex;
        flex-direction: row;
    }

    #youtube-logo-svg {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }


    .instagram-svg {
        width: 3.5vw;
        margin: 0.8vw;
    }

}


/*모바일*/
@media (min-width: 661px) and (max-width: 991px) {
    .footer-body {
        background-color: #E5E5E5;
        width: 100vw;
        height: 36vw;
        padding: 2vw 3vw;
        align-content: start;
    }

    .sortex-logo-grey {
        width: 30vw;
        margin-left: -3vw;
    }

    .nav-container {
        font-size: 3vw;
        font-family: 'JSans-300';
        color: #737373;
    }

    .nav-container-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 1.2vw;
        width: auto;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
    }


    .nav-list-item {
        margin-bottom: 2vw;
    }



    .logo-container {
        display: flex;
        flex-direction: row;
    }

    #youtube-logo-svg {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }


    .instagram-svg {
        width: 3.5vw;
        margin: 0.8vw;
    }
}


/*태블릿*/
@media (min-width: 992px) and (max-width: 1199px) {}