@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'JSans-200';
  src: url('../public/fonts/NotoSans/NotoSans-Thin.woff2') format('woff2'),
    url('../public/fonts/NotoSans/NotoSans-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: 'JSans-300';
  src: url('../public/fonts/NotoSans/NotoSans-Light.woff2') format('woff2'),
    url('../public/fonts/NotoSans/NotoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'JSans-600';
  src: url('../public/fonts/NotoSans/NotoSans-Regular.woff2') format('woff2'),
    url('../public/fonts/NotoSans/NotoSans-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'JSans-700';
  src: url('../public/fonts/NotoSans/NotoSans-Bold.woff2') format('woff2'),
    url('../public/fonts/NotoSans/NotoSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'JSans-800';
  src: url('../public/fonts/NotoSans/NotoSans-Black.woff2') format('woff2'),
    url('../public/fonts/NotoSans/NotoSans-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Work-700';
  src: url('../public/fonts/WorkSans/WorkSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Work-800';
  src: url('../public/fonts/WorkSans/WorkSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('../public/fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}




body {
  margin: 0;
  font-family: "GmarketSansTTFBold" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}