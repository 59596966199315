.form-container {
    width: 60%;
    margin: 5vw auto;
}

.pickup-title-box {
    padding: 2vw 0;

}

.form-container h1 {
    font-size: 2vw;
    font-family: 'JSans-500';
    margin-bottom: 0.5vw;
}

.form-container p {
    font-size: 1vw;
    font-family: 'JSans-300';
}

.form-input-container {
    margin: 2vw 0;
}


.form-label {
    font-size: 1.2vw;
    font-family: 'JSans-600';
}

.form-input {
    display: block;
    border: solid #d4d4d4;
    border-width: 1.5px;
    border-radius: 5px;
    width: 30%;
    height: 2.3vw;
    padding: 0 0.5vw;
    margin-top: 1vw;
    font-family: 'JSans-600';
    font-size: 1vw;
}

.address-button-container {
    display: flex;
    flex-direction: row;
}

.address-button-container button {
    width: 10%;
    font-size: 0.8vw;
    margin-left: 10px;
}

#adress2 {
    margin-top: 10px;
}


#inquiry {
    border-width: 1px;
    height: 10vw;
    width: 100%;
}

.required {
    display: inline-block;
    color: #e21818;
    font-family: 'JSans-400';
}

.char-limit {
    float: right;
}

.submit-btn {
    display: block;
    width: 10vw;
    height: 3vw;
    background-color: #e21818;
    border-radius: 5px;
    font-size: 1vw;
    font-family: 'JSans-700';
    color: white;
}

.button-flex {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;
    padding: 20px 0;
}


.pickup-text {
    font-size: 15px;
    font-family: 'JSans-300';
    padding-top: 20px;

}

/*모바일 */
@media (max-width: 549px) {

    .form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .body {
        padding: 20vw 5vw;
        width: 100%;
    }

    .pickup-title-box {
        padding: 6vw 0;

    }

    .form-container h1 {
        font-size: 7vw;
        margin-bottom: 1vw;
    }

    .form-container p {
        font-size: 3vw;
        font-family: 'JSans-300';
    }


    .form-input-container {
        margin: 2vw 0;
    }


    .form-label {
        font-size: 4vw;
        font-family: 'JSans-600';
    }

    .form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 10vw;
        margin-top: 2vw;
        font-family: 'JSans-500';
        font-size: 4vw;
        padding: 0 2vw;
    }

    .address-button-container button {
        width: 30%;
        font-size: 3vw;
        margin-left: 10px;
    }


    #contact-inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .submit-btn {
        width: 50vw;
        height: 10vw;
        border-radius: 1vw;
        font-size: 4vw;
    }

    #inquiry {
        height: 150px;
    }

    .pickup-text {}

}


/*모바일*/
@media (min-width: 550px) and (max-width: 991px) {
    .form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .body {
        padding: 20vw 5vw;
        width: 100%;
    }

    .pickup-title-box {
        padding: 30px0;

    }

    .form-container h1 {
        font-size: 25px;
        margin-bottom: 1vw;
    }

    .form-container p {
        font-size: 18px;
        font-family: 'JSans-300';
    }


    .form-input-container {
        padding: 10px 0;
    }


    .form-label {
        font-size: 20px;
        font-family: 'JSans-600';
    }

    .form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 60px;
        margin-top: 5px;
        font-family: 'JSans-500';
        font-size: 20px;
        padding: 0 2vw;
    }

    .address-button-container button {
        width: 30%;
        font-size: 18px;
        margin-left: 10px;
    }

    #address2 {
        margin-top: 10px;
    }



    #inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .submit-btn {
        width: 50vw;
        height: 10vw;
        border-radius: 1vw;
        font-size: 25px;
        margin-top: 30px;
    }

    .pickup-text {
        font-size: 15px;
        font-family: 'JSans-300';
        padding-top: 15vw;

    }

}


/*태블릿*/
@media (min-width: 992px) and (max-width: 1199px) {
    .form-container {
        height: auto;
        width: 100%;
        background-color: white;

    }

    .body {
        padding: 100px;
        width: 100%;
    }

    .pickup-title-box {
        padding: 30px 0;

    }

    .form-container h1 {
        font-size: 25px;
        margin-bottom: 1vw;
    }

    .form-container p {
        font-size: 18px;
        font-family: 'JSans-300';
    }


    .form-input-container {
        padding: 10px 0;
    }


    .form-label {
        font-size: 20px;
        font-family: 'JSans-600';
    }

    .form-input {
        display: block;
        border: solid #d4d4d4;
        border-width: 1.5px;
        border-radius: 1vw;
        width: 100%;
        height: 60px;
        margin-top: 5px;
        font-family: 'JSans-500';
        font-size: 20px;
        padding: 0 2vw;
    }

    #contact-inquiry {
        border-width: 1px;
        height: 40vw;
    }

    .submit-btn {
        width: 300px;
        height: 80px;
        border-radius: 1vw;
        font-size: 25px;
    }

    .address-button-container button {
        width: 30%;
        font-size: 20px;
        margin-left: 10px;
    }


    #inquiry {
        height: 400px;
    }


    .text {
        font-size: 15px;
        font-family: 'JSans-300';
        padding-top: 15vw;

    }
}



/*pc*/
@media (min-width: 1200px) {

    .address-button-container {
        width: 30%;
    }

    .address-button-container button {
        width: 30%;
    }

    #address {
        width: 70%;
    }



}