.slide-menu {
    position: fixed;
    top: 60px;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease;
    z-index: 1000;
    border-top:solid #eeeeee;
}

.slide-menu.open {
    right: 0;
}

.slide-menu-content {
    margin-top:20px;
    font-size: 25px;
    font-family: 'JSans-300';
    color: black;
    width:100%;
    height:100%;
}


.slide-menu-content ul{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.slide-menu-content ul li{
    padding: 15px 30px;
    width:100%;
    text-align: left;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 17px;
    color: black;
}
@media (max-width: 660px) {
    .slide-menu-content{
        font-size: 17px;   
    } 
}


@media (min-width: 661px) and (max-width: 991px) {
    .slide-menu {
        top: 95px;
    }
    
}