.about-container {
    z-index: 100;
    width: 100vw;
    height: auto;
}

.mission-container {
    width: auto;
    height: 50vw;
    margin-top:5vw;
    background-color: white;
    align-content: center;
}

.mission-text {
    width: 70%;
    margin: 0 auto;
    padding: 4vw 0;
}

.mission-text h4 {
    font-size: 3.4vw;
    font-family: 'JSans-800';
    color: #e21818;
}

.mission-text p {
    font-size: 2.9vw;
    font-family: 'Work-800';
    letter-spacing: -0.06vw;
    line-height: 3vw;
}

.mission-text p span {
    color: #e21818;
    font-size: 2.2vw;
}

.us-mobius {
    width: 70%;
    margin: 0 auto;
    padding: 4vw 0;
}


.us-mobius p {
    font-family: 'JSans-300';
    font-size: 2vw;
    color: black;
    line-height: 3vw;
}

.us-mobius p span {
    font-family: 'JSans-700';
}


.us-container {
    width: 100%;
    height: auto;
    background-color: black;
    padding: 3vw 0;
}

.us-mobius2 {
    width: 70%;
    margin: 0 auto;
    padding: 4vw 0;
}

.br {
    font-family: 'JSans-300';
    font-size: 2vw;
    line-height: 3vw;
    color: white;
}

.br span {
    font-family: 'JSans-700';
}

span .bold {
    color: #e21818;
}

.us-about {
    width: 70%;
    margin: 10vw auto;
}

.us-about h4 {
    padding: 2vw 0vw;
    font-size: 2.3vw;
    font-family: 'JSans-700';
    color: #e21818;
}

.us-about-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.us-about-profile-container {
    width: 18%;
    height: auto;
    color: white;
}

.us-about-profile-container img{
    width:100%;
    aspect-ratio: 1/1.3;
}


.us-about-profile-name {
    font-size: 1.2vw;
    font-family: 'JSans-600';
}

.us-about-profile-position {
    font-size: 1.2vw;
    font-family: 'JSans-300';
}

/*모바일 */
@media (max-width: 660px) {

    .mission-container {
        height: 400px;
        background-color: white;
        align-content: center;
        margin-top:0;
    }

    .mission-text {
        width: 90%;
        margin: 0 auto;
        padding-bottom:30px;
    }

    .mission-text h4 {
        font-size: 25px;
        font-family: 'JSans-800';
        color: #e21818;
    }

    .mission-text p {
        font-size: 20px;
        font-family: 'Work-800';
        letter-spacing: -0.06vw;
        line-height: 22px;
    }

    .mission-text p span {
        color: #e21818;
        font-size: 22px;
    }

    .us-mobius {
        width: 90%;
        margin: 0 auto;
        padding-top:50px;
    }


    .us-mobius p {
        font-family: 'JSans-300';
        font-size: 15px;
        color: black;
        line-height: 22px;
    }


    .us-container {
        width: 100%;
        height: auto;
        background-color: black;
        padding: 30px 0;
    }

    .us-mobius2 {
        width: 90%;
        margin: 0 auto;
        padding: 30px 0;
    }

    .br {
        font-family: 'JSans-300';
        font-size: 15px;
        line-height: 22px;
        color: white;
    }

    .br span {
        font-family: 'JSans-700';
    }

    span .bold {
        color: #e21818;
    }

    .us-about {
        width: 90%;
        margin: 60px auto;
    }

    .us-about h4 {
        padding: 20px 0 10px 0;
        font-size: 25px;
        font-family: 'JSans-700';
        color: #e21818;
    }

    .us-about-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .us-about-profile-container {
        width: 40%;
        height: auto;
        color: white;
        margin:5%;
    }


    .us-about-profile-name {
        font-size: 14px;
        font-family: 'JSans-600';
    }

    .us-about-profile-position {
        font-size: 14px;
        font-family: 'JSans-300';
    }


}


/*모바일*/
@media (min-width: 661px) and (max-width: 991px) {
    .about-container {
        z-index: 100;
        width: 100vw;
        height: auto;
    }

    .mission-container {
        width: auto;
        height: 500px;
        background-color: white;
        align-content: center;
    }

    .mission-text {
        width: 90%;
        margin: 0 auto;
        padding: 50px 0;
    }

    .mission-text h4 {
        font-size: 33px;
        font-family: 'JSans-800';
        color: #e21818;
    }

    .mission-text p {
        font-size: 27px;
        font-family: 'Work-800';
        letter-spacing: -0.06vw;
        line-height: 29px;
    }

    .mission-text p span {
        color: #e21818;
        font-size: 27px;
    }

    .us-mobius {
        width: 90%;
        margin: 0 auto;
        padding-top:50px;
    }


    .us-mobius p {
        font-family: 'JSans-300';
        font-size: 20px;
        color: black;
        line-height: 29px;
    }

    .us-mobius p span {
        font-family: 'JSans-700';
    }


    .us-container {
        width: 100%;
        height: auto;
        background-color: black;
        padding: 30px 0;
    }

    .us-mobius2 {
        width: 90%;
        margin: 0 auto;
        padding: 4vw 0;
    }

    .br {
        font-family: 'JSans-300';
        font-size: 20px;
        line-height: 29px;
        color: white;
    }

    .br span {
        font-family: 'JSans-700';
    }

    span .bold {
        color: #e21818;
    }

    .us-about {
        width: 90%;
        margin: 60px auto;
    }

    .us-about h4 {
        padding: 20px 0vw;
        font-size: 33px;
        font-family: 'JSans-700';
        color: #e21818;
    }

    .us-about-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .us-about-profile-container {
        width: 18%;
        height: auto;
        color: white;
    }

    .us-about-profile-name {
        font-size: 14px;
        font-family: 'JSans-600';
    }

    .us-about-profile-position {
        font-size: 14px;
        font-family: 'JSans-300';
    }
}


/*태블릿*/
@media (min-width: 992px) and (max-width: 1199px) {}