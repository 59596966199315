.mobius-container {
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
  }
  
  /* 모바일 장치용 스타일 */
  @media (max-width: 800px) {
    .mobius-container {
      height: 40vh; /* 작은 화면에서는 높이를 더 줄임 */
    }
    .overlay-container {
      height: 90vh;
    }
  }
  
  /* 더 작은 모바일 장치 (예: 480px 이하) */
  @media (max-width: 660px) {
    .mobius-container {
      height: 30vh; /* 아주 작은 화면에서는 높이를 더욱 줄임 */
    }
    .overlay-container {
      height: 80vh;
    }
  }